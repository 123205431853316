.flex {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-20 {
  margin-bottom: 20px;
}
.striked-text {
  text-decoration: line-through;
}

.layout {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  .col-6 {
    width: calc((100% - 12px) / 2);
  }
  .col-12 {
    width: 100%;
  }
}
