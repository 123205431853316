
.el-table {
  min-width: 100%;
}

.ex-table {
  width: 100%;
  header {
    color: #909399;
    font-weight: 500;
  }
  .row {
    padding: 12px 0;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #EBEEF5;
    .col {
      width: 100%;
      vertical-align: middle;
      padding: 0 10px;
      margin: 0;
    }
  }
}

.form-table {
  width: 100%;
  .heading {
    background-color: #000;
    color: #fff;
    text-align: center;;
  }
  tr td {
    padding: 5px 10px;
    border: 1px solid #000;
    .inline {
      display: inline-block;
      vertical-align: middle;
    }
    &.p-0 {
      padding: 0;
    }
    textarea {
      width: 100%;
      padding: 5px;
    }
  }
}
