.plugg-tabs {
  width: 100%;
  border-bottom: 4px solid #e6e6e6;
  .header {
    height: 35px;
    line-height: 35px;
    background-color: #4d00be;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 16px;
  }
  .empty {
    padding: 25px 20px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      text-align: center;
      width: 33%;
      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
  .tabs {
    padding: 20px;
    background-color: #4d00be;
  }
  .tab-items {
    padding: 20px;
    background-color: #f2f2f2;
    .el-button {
      text-transform: uppercase;
    }
  }
}