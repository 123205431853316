.form-inp {
  width: 100%;
  max-width: 315px;
  height: 45px;
  border-radius: 5px;
  padding: 0 15px;
  margin-bottom: 5px;
  border: 1px solid rgba(0,0,0,.2);
}

input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}

textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}

.checkbox {
  display: block;
  input {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
  label  {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
  }
}
.form-group input, .form-group select, .vdp-datepicker input {
  min-width: 20px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #c5c5c5;
  font-size: 16px;
  padding: 0 10px;
  outline: none;
  &:focus {
    border-color: #4c00be;
  }
  &.disabled, &:disabled {
    background: rgb(250,250,250);
    background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(218,218,218,1) 100%);
  }
  &.changelog-input {
    font-size: 12px; 
  }
}

textarea {
  resize: none !important;
}

.form-group textarea {
  min-width: 20px;
  width: 100%;
  min-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #c5c5c5;
  font-size: 16px;
  padding: 0 10px;
  outline: none;
  resize: none;
  overflow:hidden;
  margin-bottom: -3px; 
  &:focus {
    border-color: #4c00be;
  }
  &.disabled {
    background: rgb(250,250,250);
    background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(218,218,218,1) 100%);
  }
  &.textarea {
    font-size: 12px;
  }
}
.vdp-datepicker {
  position: relative;
  &.error input {
    border: 1px solid #d23131;
  }
  &.disabled {
    background: #fff;
    border: none; 
  }
  .vdp-datepicker__calendar-button {
    position: absolute;
    top: 6px;
    left: 7px;
  }
  input {
    padding-left: 30px;
    &.datepicker {
      font-size: 12px; 
    }
  }
}

.form-group select {
  font-size: 16px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  padding-left: 10px;
}

.form {
  padding: 35px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-group {
    width: 45%;
    margin-bottom: 15px;
    &.w100 {
      width: 100%;
    }
  }
}