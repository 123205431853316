.login-button {
  background-image: url('/assets/images/btn-img.png');
  background-repeat: no-repeat;
  background-position: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  width: 158px;
  height: 43px;
  line-height: 43px;
  padding: 0;
  text-align: center;
  margin-top: 30px;
  transition:  all ease-in-out 0.3s;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover{
    color: #fff;
    background-image: none;
    background-color: #8432ff;
    text-shadow: none;
  }
}

.btn {
  background-color: #f4f4f4;
  color: #696969;
  border-radius: 5px;
  border: none;
  height: 40px;
  padding: 0 15px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  outline: none;
  transition: all ease .2s;
  &.darker {
    background-color: #dddddd;
  }
  &.active, &:focus, &:hover {
    cursor: pointer;
    background-color: #fff;
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.32);
    -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.32);
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.32);
    border-bottom: 3px solid rgba(0,0,0,0.2);
  }
  &.active {
    background-color: #ccc;
    color: #000;
  }
  &.small {
    height: 25px;
    padding: 0 10px;
    font-size: 9px;
    border-bottom-width: 2px;
    &.active, &:focus, &:hover {
      border-bottom-width: 2px;
      -webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.2);
      box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.2);
    }
  }
}