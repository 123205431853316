ul.list {
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, .1);
  li.list-item {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    transition: all ease .2s;
    .circle {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #000;
      &.event-blue {
        background-color: #4299e1;
      }
      &.event-red {
        background-color: #ce1241;
      }
      &.event-green {
        background-color: #0ec554;
      }
      &.event-orange {
        background-color: #ee8c0c;
      }
      &.event-yellow {
        background-color: #ffe704;
        color: #666;
      }
    }
    &:hover {
      background-color: #ebf2f7;
      cursor: pointer;
    }
    &.selected {
      background-color: #f1f5f8;
      border-bottom-color: #4299e1;
      border-bottom-width: 3px;
    }
  }
}
